<template>
  <div
    :class="`bg_col_fff bor_rad_8 ${
      isLandscape
        ? 'pos_fixed l_0 t_0 w_100_vh h_100_vw z_4 dis_flex flex_dir_col'
        : 'box_sha_0_0_8_8_black_80_per m_0_16 m_t_16'
    }`"
  >
    <div class="dis_flex ali_it_cen p_16">
      <div class="flex_1 font_16 font_bold">直客进件数据表</div>

      <!-- 选项卡 -->
      <div class="dis_flex ali_it_cen">
        <img
          class="w_16 h_16 dis_flex m_r_8"
          src="http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220714/TsvtFIIYzRzkBuSudCrx.png"
        />

        <!-- 项 -->
        <div
          v-for="(item, index) in tabArr"
          :key="index"
          class="font_14"
          @click="
            tabIndex = index;
            filterForm = {};
            getList(true);
          "
        >
          <span v-show="index > 0" class="m_0_8">/</span>
          <span :class="`${tabIndex == index ? 'col_2396F5' : ''}`">{{
            item.label
          }}</span>
        </div>

        <!--  -->
      </div>

      <!-- 日期选择 -->
      <div v-show="isLandscape" class="dis_flex ali_it_cen m_l_16">
        <div
          class="dis_flex ali_it_cen"
          @click="
            filterForm = {
              ...filterForm,
              tempTimeStart: filterForm.tempTimeStart || new Date(),
              timeStep: 0,
              timeDialogShow: true,
            }
          "
        >
          <img
            class="w_16 h_16 dis_flex"
            src="http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220714/TsvtFIIYzRzkBuSudCrx.png"
          />

          <div class="font_14 col_2396F5 m_l_4">
            {{ filterForm.timeStartStr || '开始日期' }}
          </div>

          <div class="font_14 m_0_8">-</div>

          <div class="font_14 col_2396F5">
            {{ filterForm.timeEndStr || '结束日期' }}
          </div>

          <!--  -->
        </div>

        <!-- 清除 -->
        <img
          v-show="filterForm.timeStartStr"
          class="w_16 h_16 dis_flex m_l_4"
          src="http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220705/sYOxRslRIFJplIZHjJAB.png"
          @click="
            filterForm = {};
            tabIndex = 0;
            getList(true);
          "
        />

        <!--  -->
      </div>

      <!-- 日期选择 弹窗 -->
      <van-popup v-model="filterForm.timeDialogShow" position="bottom">
        <van-datetime-picker
          v-if="filterForm.timeStep == 0"
          v-model="filterForm.tempTimeStart"
          type="date"
          title="开始日期"
          key="开始日期"
          @confirm="
            filterForm = {
              ...filterForm,
              timeStep: 1,
            }
          "
          @cancel="filterForm = { ...filterForm, timeDialogShow: false }"
        />

        <van-datetime-picker
          v-if="filterForm.timeStep == 1"
          v-model="filterForm.tempTimeEnd"
          type="date"
          title="结束日期"
          key="结束日期"
          :min-date="filterForm.tempTimeStart"
          @confirm="
            (v) => {
              filterForm = {
                ...filterForm,
                timeStart: moment(filterForm.tempTimeStart).format(
                  'YYYY-MM-DD 00:00:00'
                ),
                timeStartStr: moment(filterForm.tempTimeStart).format(
                  'YYYY-MM-DD'
                ),
                timeEnd: moment(v).format('YYYY-MM-DD 23:59:59'),
                timeEndStr: moment(v).format('YYYY-MM-DD'),
                timeDialogShow: false,
              };
              tabIndex = -1;
              getList(true);
            }
          "
          @cancel="filterForm = { ...filterForm, timeStep: 0 }"
        />

        <!--  -->
      </van-popup>

      <!-- 全屏|关闭 -->
      <div class="font_14 col_2396F5 m_l_16" @click="landscapeChange()">
        {{ isLandscape ? '关闭' : '全屏' }}
      </div>

      <!--  -->
    </div>

    <!-- 列表 -->
    <vxe-table
      :loading="loading"
      :data="list"
      border
      resizable
      align="center"
      show-overflow
    >
      <vxe-column title="商户名称" min-width="96" fixed="left">
        <template #default="{ row }">
          {{ row.channelName || '-' }}
        </template>
      </vxe-column>

      <vxe-column title="进件数" min-width="80">
        <template #default="{ row }">
          {{ columnAmount(row.loanCount, { fixed: 0 }) }}
        </template>
      </vxe-column>

      <vxe-column title="预审通过数" min-width="128">
        <template #default="{ row }">
          {{ columnAmount(row.preCount, { fixed: 0 }) }}
        </template>
      </vxe-column>

      <vxe-column title="审批量" min-width="80">
        <template #default="{ row }">
          {{ columnAmount(row.approveCount, { fixed: 0 }) }}
        </template>
      </vxe-column>

      <vxe-column title="审批通过数" min-width="128">
        <template #default="{ row }">
          {{ columnAmount(row.approveAdoptCount, { fixed: 0 }) }}
        </template>
      </vxe-column>

      <vxe-column title="批款金额（元）" min-width="128">
        <template #default="{ row }">
          {{ columnAmount(row.approveAmt) }}
        </template>
      </vxe-column>

      <vxe-column title="放款数" min-width="80">
        <template #default="{ row }">
          {{ columnAmount(row.putCount, { fixed: 0 }) }}
        </template>
      </vxe-column>

      <vxe-column title="放款金额（元）" min-width="128">
        <template #default="{ row }">
          {{ columnAmount(row.putOutAmt) }}
        </template>
      </vxe-column>

      <vxe-column title="待签数" min-width="80">
        <template #default="{ row }">
          {{ columnAmount(row.signCount, { fixed: 0 }) }}
        </template>
      </vxe-column>

      <vxe-column title="待签金额（元）" min-width="128">
        <template #default="{ row }">
          {{ columnAmount(row.signAmt) }}
        </template>
      </vxe-column>

      <!--  -->
    </vxe-table>

    <!-- 分页 -->
    <!-- <van-pagination
      v-show="isLandscape"
      class="m_t_8"
      v-model="pageNum"
      :total-items="pageTotal"
      :items-per-page="pageSize"
      force-ellipses
      @change="getList()"
    /> -->

    <!--  -->
  </div>
</template>

<script>
const moment = window.moment;
import { Toast } from 'vant';
import { columnAmount } from '@/utils/tools';

import { brokerDashboardChannelStatisticsListPost } from '@/api/index';

const defaultFilterForm = () => {
  return {};
};

export default {
  components: {},
  data() {
    return {
      loading: false,
      filterForm: { ...defaultFilterForm() },
      tabIndex: 0,
      isLandscape: false,
      list: [],
      pageNum: 1,
      pageSize: 4,
      pageTotal: 0,

      //
    };
  },
  computed: {
    // 选项卡
    tabArr: () => [
      {
        label: '日',
        value: 'CURRENT_DAY',
      },
      { label: '周', value: 'CURRENT_WEEK' },
      { label: '月', value: 'CURRENT_MONTH' },
    ],

    //
  },
  mounted() {
    this.getList();

    //
  },
  methods: {
    moment,
    columnAmount,

    // 横屏 变化
    async landscapeChange() {
      this.isLandscape = !this.isLandscape;

      // 横屏
      if (this.isLandscape) {
        this.scrollTop =
          document.documentElement.scrollTop ||
          window.pageYOffset ||
          document.body.scrollTop;
        document.body.style =
          'width: 100vh;height: 100vw;transform: translate(100vw,0) rotate(90deg);transform-origin: 0 0;';
        this.getList(true);
      } else {
        this.filterForm = {};
        this.tabIndex = 0;

        document.body.style = null;

        Toast.loading({
          message: '请稍后..',
          overlay: true,
        });

        await this.getList(true);

        Toast.clear();

        setTimeout(() => {
          window.scrollTo({
            top: this.scrollTop,
          });
        }, 0);

        //
      }

      //
    },

    // 获取 列表
    async getList(needReload) {
      if (needReload) {
        this.pageNum = 1;
      }

      try {
        this.loading = true;

        const data = await brokerDashboardChannelStatisticsListPost({
          /* current: this.pageNum,
          size: this.isLandscape ? this.pageSize : 999999, */
          duration:
            this.tabIndex > -1 ? this.tabArr[this.tabIndex].value : null,
          ...this.filterForm,
        });

        /* this.list = data.records;
        this.pageTotal = data.total; */
        this.list = data;

        //
      } catch (err) {
        Toast(err);
      } finally {
        this.loading = false;
      }

      //
    },

    //
  },
};
</script>
